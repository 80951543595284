// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f-contract-garanties {
  list-style: disc;
  padding-left: 16px;
}
`, "",{"version":3,"sources":["webpack://./app/pages/standard/components/Contract.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;AACF","sourcesContent":[".f-contract-garanties {\n  list-style: disc;\n  padding-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
