// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f-block-field {
  color: #4f121c;
  margin: 8px 0px;
}
.f-block-field-label {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 4px;
}
`, "",{"version":3,"sources":["webpack://./app/components/BlockField.less"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;AACF;AACA;EACE,eAAA;EACA,yBAAA;EACA,iBAAA;EACA,kBAAA;AACF","sourcesContent":[".f-block-field {\n  color: #4f121c;\n  margin: 8px 0px;\n}\n.f-block-field-label {\n  font-size: 16px;\n  text-transform: uppercase;\n  font-weight: bold;\n  margin-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
