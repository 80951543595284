// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f-textarea {
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 8px;
}
.f-textarea-error {
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  border-color: #fca5a5;
  padding: 8px;
}
`, "",{"version":3,"sources":["webpack://./app/components/input/TextArea.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,qCAAA;EACA,YAAA;AACF;AACA;EACE,WAAA;EACA,kBAAA;EACA,qCAAA;EACA,qBAAA;EACA,YAAA;AACF","sourcesContent":[".f-textarea {\n  width: 100%;\n  border-radius: 4px;\n  border: solid 1px rgba(0, 0, 0, 0.15);\n  padding: 8px;\n}\n.f-textarea-error {\n  width: 100%;\n  border-radius: 4px;\n  border: solid 1px rgba(0, 0, 0, 0.15);\n  border-color: #fca5a5;\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
