// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-icon {
  text-align: center;
  margin: 0 auto;
  flex-shrink: 0;
  margin: auto;
}
.document-name {
  margin-top: auto;
}
`, "",{"version":3,"sources":["webpack://./app/pages/standard/components/DocumentsList.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,cAAA;EACA,YAAA;AACF;AACA;EACE,gBAAA;AACF","sourcesContent":[".document-icon {\n  text-align: center;\n  margin: 0 auto;\n  flex-shrink: 0;\n  margin: auto;\n}\n.document-name {\n  margin-top: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
