// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1MmgWxP.ttf) format('truetype');
}
body {
  min-height: 100vh;
  font-size: 14px;
  background-color: #eee;
}
`, "",{"version":3,"sources":["webpack://./app/index.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,4FAAA;AACF;AACA;EACE,iBAAA;EACA,eAAA;EACA,sBAAA;AACF","sourcesContent":["@font-face {\n  font-family: 'Roboto';\n  font-style: normal;\n  font-weight: 100;\n  font-display: swap;\n  src: url(https://fonts.gstatic.com/s/roboto/v32/KFOkCnqEu92Fr1MmgWxP.ttf) format('truetype');\n}\nbody {\n  min-height: 100vh;\n  font-size: 14px;\n  background-color: #eee;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
